var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Expose `Delegator`.
 */
exports = Delegator;
/**
 * Initialize a delegator.
 *
 * @param {Object} proto
 * @param {String} target
 * @api public
 */

function Delegator(proto, target) {
  if (!((this || _global) instanceof Delegator)) return new Delegator(proto, target);
  (this || _global).proto = proto;
  (this || _global).target = target;
  (this || _global).methods = [];
  (this || _global).getters = [];
  (this || _global).setters = [];
  (this || _global).fluents = [];
}
/**
 * Delegate method `name`.
 *
 * @param {String} name
 * @return {Delegator} self
 * @api public
 */


Delegator.prototype.method = function (name) {
  var proto = (this || _global).proto;
  var target = (this || _global).target;

  (this || _global).methods.push(name);

  proto[name] = function () {
    return (this || _global)[target][name].apply((this || _global)[target], arguments);
  };

  return this || _global;
};
/**
 * Delegator accessor `name`.
 *
 * @param {String} name
 * @return {Delegator} self
 * @api public
 */


Delegator.prototype.access = function (name) {
  return this.getter(name).setter(name);
};
/**
 * Delegator getter `name`.
 *
 * @param {String} name
 * @return {Delegator} self
 * @api public
 */


Delegator.prototype.getter = function (name) {
  var proto = (this || _global).proto;
  var target = (this || _global).target;

  (this || _global).getters.push(name);

  proto.__defineGetter__(name, function () {
    return (this || _global)[target][name];
  });

  return this || _global;
};
/**
 * Delegator setter `name`.
 *
 * @param {String} name
 * @return {Delegator} self
 * @api public
 */


Delegator.prototype.setter = function (name) {
  var proto = (this || _global).proto;
  var target = (this || _global).target;

  (this || _global).setters.push(name);

  proto.__defineSetter__(name, function (val) {
    return (this || _global)[target][name] = val;
  });

  return this || _global;
};
/**
 * Delegator fluent accessor
 *
 * @param {String} name
 * @return {Delegator} self
 * @api public
 */


Delegator.prototype.fluent = function (name) {
  var proto = (this || _global).proto;
  var target = (this || _global).target;

  (this || _global).fluents.push(name);

  proto[name] = function (val) {
    if ("undefined" != typeof val) {
      (this || _global)[target][name] = val;
      return this || _global;
    } else {
      return (this || _global)[target][name];
    }
  };

  return this || _global;
};

export default exports;